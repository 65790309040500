@import url("https://fonts.googleapis.com/css?family=Frank+Ruhl+Libre:300,400,500,700,900|Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap&subset=cyrillic");
@font-face {
  font-family: 'font-icon';
  src: url("../fonts/font-icon/font-icon.eot?yj7fja");
  src: url("../fonts/font-icon/font-icon.eot?yj7fja#iefix") format("embedded-opentype"), url("../fonts/font-icon/font-icon.ttf?yj7fja") format("truetype"), url("../fonts/font-icon/font-icon.woff?yj7fja") format("woff"), url("../fonts/font-icon/font-icon.svg?yj7fja#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'font-icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-right:before {
  content: "\e900"; }

.icon-left:before {
  content: "\e901"; }

.icon-load:before {
  content: "\e902"; }

.icon-linkedIn:before {
  content: "\e903"; }

.icon-redlab:before {
  content: "\e904"; }

.icon-earth:before {
  content: "\e905"; }

.icon-envelope:before {
  content: "\e906"; }

.icon-phone:before {
  content: "\e907"; }

.icon-close:before {
  content: "\e908"; }

.icon-dots:before {
  content: "\e909"; }

.icon-coat:before {
  content: "\e910"; }

.icon-jackets:before {
  content: "\e911"; }

.icon-raincoat:before {
  content: "\e912"; }

.icon-list:before {
  content: "\e913"; }

.icon-recycling:before {
  content: "\e914"; }

.icon-standards:before {
  content: "\e915"; }

.icon-energy:before {
  content: "\e916"; }

.icon-certificate:before {
  content: "\e917"; }

.icon-checked:before {
  content: "\e918"; }

.icon-upload:before {
  content: "\e919"; }

.icon-filter:before {
  content: "\e920"; }

.icon-bars:before {
  content: "\e921"; }

.icon-play:before {
  content: "\e922"; }

html {
  background-color: #f7f6f2; }

body {
  position: relative;
  min-width: 22.85714em;
  color: #27282a;
  font-family: "Open Sans", "Frank Ruhl Libre", sans-serif;
  overflow: hidden; }

* {
  outline: none;
  word-wrap: break-word;
  -webkit-tap-highlight-color: transparent; }

/*a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $MainText;
  transition: .3s;
}*/
a, a:focus, a:hover {
  text-decoration: none; }

ol, ul {
  list-style: none;
  margin: 0;
  padding: 0; }

img {
  border: 0;
  max-width: 100%;
  vertical-align: middle; }

button {
  padding: 0;
  cursor: pointer; }

input,
button,
textarea {
  -webkit-appearance: none; }

#BlowupLens {
  top: 0; }

.main-btn {
  width: 405px;
  height: 92px;
  padding: 10px 52px;
  display: flex;
  align-items: center;
  position: relative;
  border: none;
  background-color: #eebb56;
  color: #ffffff;
  font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 700; }
  .main-btn span {
    width: 100%;
    display: block;
    position: relative; }
    .main-btn span:after {
      content: '';
      width: 77px;
      height: 3px;
      background-color: #ffffff;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: .3s ease-in-out; }
  .main-btn.left-line span {
    padding: 0 0 0 80px;
    text-align: right; }
    .main-btn.left-line span:after {
      left: 0; }
  .main-btn.left-line:hover span:after {
    width: 129px;
    left: -52px; }
  .main-btn.right-line span {
    padding: 0 80px 0 0;
    text-align: left; }
    .main-btn.right-line span:after {
      right: 0; }
  .main-btn.right-line:hover span:after {
    width: 129px;
    right: -52px; }

.mobile-sidebar {
  padding: 0;
  width: 30px;
  display: none;
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s ease-in-out; }
  .mobile-sidebar button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: none;
    background-color: #edece6;
    cursor: pointer; }
    .mobile-sidebar button i {
      font-family: 'font-icon';
      color: #998877;
      font-size: 10px; }
  .mobile-sidebar.mobile-filter {
    /*opacity: 1;
    pointer-events: auto;*/ }
    .mobile-sidebar.mobile-filter button i:before {
      content: "\e920"; }
  .mobile-sidebar.mobile-menu {
    /*opacity: 1;
    pointer-events: auto;*/ }
    .mobile-sidebar.mobile-menu button i:before {
      content: "\e921"; }
  .mobile-sidebar.open button i:before {
    content: "\e908"; }

header {
  position: relative;
  z-index: 8; }
  header.hide .main-logo a {
    opacity: 0; }

.header-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 55px 0; }

.main-logo a {
  opacity: 1;
  transition: opacity .4s ease-in-out; }

.fixed-logo a,
.main-logo a {
  display: block;
  max-width: 122px; }
  .fixed-logo a img,
  .main-logo a img {
    max-width: 100%;
    max-height: 100%; }
  .fixed-logo a.fixed, .fixed-logo a.open,
  .main-logo a.fixed,
  .main-logo a.open {
    position: fixed;
    top: 67px;
    left: 50px;
    z-index: 15; }

.header-list {
  display: flex; }

.header-item {
  margin: 0 68px 0 0;
  position: relative; }
  .header-item:hover .item-text {
    color: #eebb56;
    text-decoration: underline; }
  .header-item a {
    display: flex;
    align-items: center; }
  .header-item p {
    margin: 0;
    padding: 0 18px 0 0;
    position: relative;
    display: flex;
    align-items: center; }
    .header-item p:after {
      content: '\e900';
      font-family: 'font-icon';
      font-size: 9px;
      color: #eebb56;
      position: absolute;
      right: 0;
      transform: rotate(90deg); }
  .header-item ul {
    padding: 3px 0;
    position: absolute;
    top: calc(100% - 9px);
    left: 44px;
    right: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s ease-in-out; }
    .header-item ul li {
      padding: 3px 10px 3px 16px; }
      .header-item ul li a {
        display: block;
        color: #27282a;
        font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
        font-size: 18px;
        font-weight: 400;
        white-space: nowrap;
        transition: color .3s ease-in-out; }
        .header-item ul li a:hover {
          color: #eebb56;
          text-decoration: underline; }
    .header-item ul.open {
      opacity: 1;
      pointer-events: auto; }
  .header-item .item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 100%;
    background-color: #edece6; }
    .header-item .item-icon i {
      font-size: 16px;
      color: #998877; }
  .header-item .item-text {
    padding: 0 0 0 16px;
    color: #27282a;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 18px;
    font-weight: 400;
    white-space: nowrap;
    transition: color .3s ease-in-out; }

.left-sidebar {
  width: 240px;
  /*display: flex;
  flex-direction: column;
  justify-content: space-between;*/
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 7;
  display: flex; }

.catalog-toolbar {
  max-height: 100%;
  display: flex;
  padding: 198px 0 30px 0; }

.catalog-toolbar-wrap {
  width: 100%;
  padding: 0 33px 0 48px;
  overflow: auto; }

.catalog-toolbar-title {
  padding: 0 0 40px 0; }
  .catalog-toolbar-title h1 {
    margin: 0;
    color: #27282a;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 32px;
    font-weight: 700; }

.filter-item {
  padding: 12px 0; }
  .filter-item h3 {
    margin: 0;
    padding: 0 18px 0 0;
    color: #27282a;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    position: relative;
    cursor: pointer; }
    .filter-item h3:after {
      content: '\e901';
      font-family: 'font-icon';
      color: #eebb56;
      position: absolute;
      top: 3px;
      right: 0;
      font-size: 9px;
      transition: transform .3s ease-in-out; }
  .filter-item ol {
    display: none;
    padding: 12px 0; }
    .filter-item ol li {
      padding: 8px 0; }
      .filter-item ol li label {
        display: flex;
        position: relative;
        cursor: pointer; }
        .filter-item ol li label input {
          position: absolute;
          opacity: 0;
          visibility: hidden; }
          .filter-item ol li label input:checked + span:before {
            border-color: #eebb56; }
          .filter-item ol li label input:checked + span:after {
            opacity: 1; }
        .filter-item ol li label span {
          display: block;
          width: 100%;
          padding: 0 0 0 31px;
          position: relative;
          color: #27282a;
          font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
          font-size: 15px;
          font-weight: 400;
          transition: font-weight .3s ease-in-out; }
          .filter-item ol li label span:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 14px;
            height: 14px;
            border-radius: 100%;
            border: 1px solid #d1d0cb;
            transition: border-color .3s ease-in-out; }
          .filter-item ol li label span:after {
            content: '';
            position: absolute;
            top: 1px;
            left: 1px;
            width: 12px;
            height: 12px;
            border-radius: 100%;
            border: 2px solid #ffffff;
            background: #eebb56;
            opacity: 0;
            transition: opacity .3s ease-in-out; }
        .filter-item ol li label:hover span {
          font-weight: 700; }
          .filter-item ol li label:hover span:before {
            border-color: #eebb56; }
  .filter-item.open {
    /* ol {
       display: block;
     }*/ }
    .filter-item.open h3:after {
      transform: rotate(-90deg); }

.page-nav {
  width: 100%;
  max-height: 100%;
  padding: 230px 0 30px 0;
  display: flex;
  flex-direction: column; }
  .page-nav ol {
    width: 100%;
    overflow: auto; }
    .page-nav ol li {
      padding: 15% 0; }
      .page-nav ol li a {
        display: block;
        padding: 0 10px 0 60px;
        position: relative;
        color: #27282a;
        font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 1px;
        text-transform: uppercase;
        transition: color .3s ease-in-out; }
        .page-nav ol li a:before {
          content: '';
          width: 46px;
          height: 3px;
          background-color: #eebb56;
          opacity: 0;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          transition: opacity .3s ease-in-out; }
        .page-nav ol li a:hover {
          color: #eebb56; }
      .page-nav ol li.active a {
        font-weight: 700; }
        .page-nav ol li.active a:before {
          opacity: 1; }

.right-sidebar {
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 15;
  pointer-events: none; }
  .right-sidebar .modal-close {
    display: none; }
  .right-sidebar.modal-open .catalog-btn {
    display: none; }
  .right-sidebar.modal-open .modal-close {
    display: flex;
    background-color: #f7f6f2; }

.catalog-btn,
.modal-close {
  min-height: 150px;
  padding: 48px 15px 48px 56px;
  background-color: #ffffff;
  display: flex;
  align-items: flex-end;
  color: #27282a;
  font-size: 42px;
  pointer-events: auto; }
  .catalog-btn i,
  .modal-close i {
    transition: color .3s ease-in-out; }
  .catalog-btn span,
  .modal-close span {
    display: block;
    padding: 3px 0;
    margin: 0 0 0 21px;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-weight: 700;
    position: relative; }
    .catalog-btn span:after,
    .modal-close span:after {
      content: '';
      width: 34px;
      height: 3px;
      background-color: #27282a;
      transition: width .3s ease-in-out;
      position: absolute;
      left: 0;
      bottom: 0; }
  .catalog-btn:hover i,
  .modal-close:hover i {
    color: #eebb56; }
  .catalog-btn:hover span:after,
  .modal-close:hover span:after {
    width: 100%; }

.go-back {
  min-height: 270px;
  display: flex;
  align-items: flex-end;
  padding: 30px 15px 55px 55px;
  background-color: #0070b7;
  color: #ffffff;
  font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
  font-size: 42px;
  font-weight: 700;
  line-height: 44px;
  pointer-events: auto; }
  .go-back span {
    display: block;
    padding: 0 0 19px 0;
    position: relative; }
    .go-back span:after {
      content: '';
      width: 34px;
      height: 3px;
      background-color: #ffffff;
      transition: width .3s ease-in-out;
      position: absolute;
      left: 0;
      bottom: 0; }
  .go-back:hover span:after {
    width: 100%; }

.send-order {
  min-height: 270px;
  display: flex;
  align-items: self-start;
  flex-direction: column;
  justify-content: space-between;
  padding: 58px 15px 55px 55px;
  background-color: #0070b7;
  color: #ffffff;
  font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
  pointer-events: auto; }
  .send-order span {
    display: block;
    padding: 0 0 11px 0; }
    .send-order span.request {
      width: 100%;
      color: #ffffff;
      font-size: 17px;
      font-weight: 400; }
    .send-order span.send-btn {
      position: relative;
      font-size: 42px;
      font-weight: 700; }
      .send-order span.send-btn:after {
        content: '';
        width: 34px;
        height: 3px;
        background-color: #ffffff;
        transition: width .3s ease-in-out;
        position: absolute;
        left: 0;
        bottom: 0; }
  .send-order:hover span.send-btn:after {
    width: 100%; }

.chosen-product {
  margin: 27px 0 0 0;
  padding: 0 0 70px 0;
  background-color: #edece6; }

.chosen-product-wrap {
  display: flex;
  flex-wrap: wrap; }

.item-slider-wrap {
  width: 44.4%;
  padding-top: 8px;
  display: flex;
  position: relative;
  z-index: 2; }
  .item-slider-wrap .slider-control {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0; }
  .item-slider-wrap .section-pagination,
  .item-slider-wrap .section-arrows {
    background-color: #edece6; }

.item-slider-wrap,
.product-data {
  padding-bottom: 107px; }

.item-view {
  display: flex;
  margin-left: -316px;
  padding-left: 316px; }
  .item-view .swiper-slide {
    background-color: #ffffff;
    display: flex; }
    .item-view .swiper-slide a {
      display: flex;
      justify-content: center;
      align-items: flex-end; }
    .item-view .swiper-slide img {
      opacity: 0;
      transition: opacity .3s ease-in-out; }
      .item-view .swiper-slide img.swiper-lazy-loaded {
        opacity: 1; }

.product-data {
  width: calc(100% - 44.4%);
  position: relative;
  z-index: 1; }

.slider-control {
  padding: 9px 0; }

.pagination-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1; }
  .pagination-wrap:after {
    content: '';
    border-bottom: 2px solid #e3e2dc;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    z-index: -1;
    pointer-events: none; }

.section-pagination {
  width: auto;
  padding: 0 16px 0 0;
  display: flex;
  align-items: center;
  position: static;
  color: #27282a;
  font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 400; }
  .section-pagination .pagination-current {
    padding: 0 8px 0 0;
    color: #eebb56;
    font-size: 44px;
    font-weight: 700; }

.section-arrows {
  display: flex;
  padding: 0 0 0 16px; }
  .section-arrows > div:first-child {
    margin: 0 10px 0 0; }

/*.section-prev {
  margin: 0 10px 0 0;
}*/
.section-prev,
.section-next {
  width: 68px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: #eebb56;
  border-radius: 100%;
  background-color: #ffffff; }
  .section-prev i,
  .section-next i {
    transition: transform .3s ease-in-out; }
  .section-prev:not(.button-disabled),
  .section-next:not(.button-disabled) {
    cursor: pointer; }
  .section-prev.button-disabled,
  .section-next.button-disabled {
    color: #d9d7cc;
    background-color: #e3e2dc; }
  .section-prev:hover i,
  .section-next:hover i {
    transform: rotate(360deg); }

.pagination-bullet {
  margin: 0 7px;
  display: inline-block;
  cursor: pointer; }
  .pagination-bullet:after {
    content: '\e913';
    font-family: 'font-icon';
    color: #e0ded6;
    font-size: 17px;
    transition: color .3s ease-in-out; }
  .pagination-bullet.active-bullet:after {
    color: #eebb56; }

.section-bullets {
  width: calc(100% - 146px);
  padding: 0 116px 0 10px;
  text-align: center; }

.product-info {
  padding: 0 0 0 81px;
  position: relative;
  z-index: 1; }
  .product-info:first-child:after {
    content: '';
    width: 150vw;
    background-color: #f7f6f2;
    position: absolute;
    top: -1px;
    right: 50%;
    bottom: 0;
    transform: translateX(38%);
    z-index: -1;
    pointer-events: none; }

.product-name {
  padding: 0 0 25px 0; }
  .product-name h1 {
    margin: 0;
    color: #27282a;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 44px;
    font-weight: 700; }
  .product-name p {
    margin: 0;
    padding: 8px 0 0 0;
    color: #27282a;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 26px;
    font-weight: 400; }

.product-list {
  padding: 0 0 34px 0; }
  .product-list li {
    padding: 12px 0;
    color: #27282a;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 18px;
    font-weight: 400; }
    .product-list li b {
      font-weight: 700; }

.product-price {
  padding: 37px 0 0 0; }
  .product-price p {
    margin: 0;
    color: #27282a;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 29px;
    font-weight: 700; }

.product-order {
  max-width: 381px;
  padding: 41px 0 0 0; }
  .product-order p {
    margin: 0;
    padding: 0 0 26px 0;
    color: #27282a;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 23px;
    font-weight: 700; }
  .product-order button {
    width: 381px;
    height: 92px;
    /*padding: 10px 52px;
    display: flex;
    align-items: center;
    position: relative;
    border: none;
    background-color: #eebb56;

    color: #ffffff;
    font-family: $FrankRuhlLibre;
    font-size: 20px;
    font-weight: 700;
    span {
      width: 100%;
      display: block;
      padding: 0 80px 0 0;
      position: relative;
      text-align: left;

      &:after {
        content: '';
        width: 77px;
        height: 3px;
        background-color: #ffffff;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }*/ }

.goods-list {
  padding: 36px 0; }

.goods-list-title h2 {
  margin: 0;
  color: #27282a;
  font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
  font-size: 64px;
  font-weight: 700;
  letter-spacing: 0.8px; }

.goods-list-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1px 0 0;
  padding: 26px 0 0 0; }

.goods-item {
  width: calc((100% / 3) - 1px);
  margin: 0 1px 1px 0;
  padding-bottom: 37.5%;
  position: relative;
  background-color: #ffffff; }
  .goods-item a {
    display: block;
    padding: 27px 30px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    transition: box-shadow .3s ease-in-out; }
    .goods-item a span {
      display: block;
      max-width: 160px;
      padding: 0 0 8px 0;
      position: relative;
      color: #27282a;
      font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
      font-size: 23px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0.7px;
      transition: color .3s ease-in-out; }
      .goods-item a span:after {
        content: '';
        width: 26px;
        height: 3px;
        background-color: #f2f1ed;
        position: absolute;
        left: 0;
        bottom: 0;
        transition: width .3s ease-in-out, background-color .3s ease-in-out; }
  .goods-item img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    pointer-events: none; }
    .goods-item img.default {
      transition: opacity .5s ease-in-out;
      z-index: 2; }
    .goods-item img.hover {
      z-index: 1; }
  .goods-item:hover a {
    box-shadow: 0 2px 67px #e1dfd7; }
    .goods-item:hover a span {
      color: #eebb56; }
      .goods-item:hover a span:after {
        width: 58px;
        background-color: #eebb56; }
  .goods-item:hover img.default {
    opacity: 0; }

.load-more {
  padding: 79px 0 0 0;
  text-align: center; }
  .load-more button {
    width: 241px;
    display: inline-flex;
    padding: 30px 65px 30px 32px;
    position: relative;
    color: #ffffff;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: 700;
    border: none;
    background-color: #eebb56; }
    .load-more button i {
      font-size: 16px;
      position: absolute;
      top: 42%;
      right: 34px;
      pointer-events: none;
      transform: translateY(-50%);
      animation: spin 5s infinite linear;
      animation-play-state: paused; }
  .load-more.processing button i {
    animation-play-state: running; }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.goods-pagination {
  display: flex;
  justify-content: center;
  padding: 35px 0; }
  .goods-pagination .pagination-wrap {
    width: 100%;
    max-width: 441px; }
  .goods-pagination .section-pagination,
  .goods-pagination .section-arrows {
    background-color: #f7f6f2; }

.production-notice {
  padding: 59px 0 21px 0; }
  .production-notice p {
    margin: 0;
    max-width: 930px;
    color: #27282a;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 44px;
    font-weight: 700;
    letter-spacing: 0.4px;
    line-height: 1.34; }

.map-title {
  position: relative;
  padding-bottom: 125px;
  margin-bottom: -33px; }
  .map-title:after {
    content: '';
    width: 2px;
    height: 125px;
    background-color: #eebb56;
    position: absolute;
    bottom: 0;
    left: 39px; }
  .map-title h3 {
    margin: 0;
    padding: 0 0 16px 0;
    color: #27282a;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 44px;
    font-weight: 400; }

.contacts-wrap {
  display: flex;
  flex-wrap: wrap; }

.map-wrap {
  width: calc(100% - 38.5%);
  position: relative; }

#google-map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.contacts {
  width: 38.5%;
  padding: 71px 82px 30px 77px;
  background-color: #ffffff; }
  .contacts h4 {
    margin: 0;
    color: #27282a;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 64px;
    font-weight: 700; }

.contacts-info-list {
  padding: 17px 0 0 2px; }

.contacts-info {
  padding: 18px 0 18px 2px; }
  .contacts-info p {
    margin: 0;
    color: #27282a;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 19px;
    font-weight: 400; }
  .contacts-info a {
    display: block;
    margin: 11px 0 0 0;
    color: #27282a;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 24px;
    font-weight: 700;
    transition: color .3s ease-in-out; }
    .contacts-info a:hover {
      color: #eebb56; }

.social-links {
  padding: 29px 0 29px 5px; }
  .social-links a {
    padding: 7px;
    margin-right: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 15px; }
    .social-links a.linked-in {
      width: 40px;
      height: 40px;
      color: #ffffff;
      border-radius: 100%;
      background-color: #0274b3; }

.contacts-section {
  background-color: #ffffff; }
  .contacts-section .contacts-bottom {
    padding-top: 36px; }
  .contacts-section .ready-for {
    display: block; }

.ready-for {
  display: none;
  margin: 0 -11px 0 0;
  padding: 26px 0 26px 5px; }
  .ready-for p {
    margin: 0;
    padding: 0 0 0 25px;
    color: #27282a;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 28px;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: 0.2px;
    border-left: 3px solid #eebb56; }

.contacts-bottom {
  padding: 179px 0 20px 1px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between; }

.site-rights {
  padding: 5px 0; }
  .site-rights p {
    color: #27282a;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 400; }
    .site-rights p a {
      display: block;
      padding: 3px 0 0 0;
      color: inherit;
      text-decoration: underline; }

.certificate-img {
  padding: 5px 15px; }
  .certificate-img img {
    max-width: 47px; }

.made-in {
  padding: 5px 0; }
  .made-in a span {
    display: block;
    color: #27282a;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 400; }
  .made-in a i {
    font-size: 14px;
    color: #ed1c24; }

.gmnoprint,
.gm-fullscreen-control {
  display: none; }

.catalog-slider {
  position: relative; }

.category-view-control {
  padding: 0 0 104px 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0; }
  .category-view-control .slider-control {
    width: 38%; }
  .category-view-control .section-pagination,
  .category-view-control .section-arrows {
    background-color: #f7f6f2; }
  .category-view-control .pagination-wrap:after {
    border-bottom: 2px solid #edece6; }

.catalog-category {
  min-height: calc(100vh - 89px);
  display: flex;
  flex-wrap: wrap; }

.category-img {
  width: 50%;
  position: relative;
  z-index: 1; }

.category-view {
  margin: -65px 0 0 0; }
  .category-view .swiper-slide {
    background-color: #f7f6f2; }

.category-img-wrap {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -147px;
  left: -117px; }
  .category-img-wrap img {
    max-width: 100%;
    max-height: 100%;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    pointer-events: none; }
    .category-img-wrap img.swiper-lazy-loaded {
      opacity: 1; }
  .category-img-wrap a {
    padding: 10px;
    color: #0070b7;
    position: absolute;
    left: 32%;
    top: 43%; }
    .category-img-wrap a span {
      width: 137px;
      height: 137px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;
      border-radius: 100%;
      background-color: #ffffff;
      position: relative;
      z-index: 2; }
      .category-img-wrap a span i {
        font-size: 25px;
        padding: 0 0 4px 4px; }
      .category-img-wrap a span:before {
        content: '';
        border-radius: 100%;
        border: 3px solid #ffffff;
        position: absolute;
        top: -9px;
        left: -9px;
        right: -9px;
        bottom: -9px;
        z-index: 1;
        pointer-events: none; }

.category-info {
  width: 50%;
  padding: 65px 0 190px 0;
  position: relative;
  z-index: 2;
  pointer-events: none; }

.category-title {
  padding: 10px 0 0 0;
  pointer-events: auto; }
  .category-title h3 {
    margin: 0;
    color: #27282a;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 64px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1.3; }
  .category-title p {
    margin: 0;
    padding: 11px 0 0 0;
    color: #ffffff;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 116px;
    font-weight: 700;
    text-transform: uppercase; }

.not-found-wrap {
  padding: 44px 0 155px 0; }

.error-type h2 {
  margin: 0;
  margin-top: -16.5%;
  color: #27282a;
  font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
  font-size: 240px;
  font-weight: 700;
  text-align: center;
  position: relative;
  z-index: 1; }

.error-type p {
  margin: 0;
  color: #ffffff;
  font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
  font-size: 300px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  white-space: nowrap;
  letter-spacing: 5px; }

.page-error h1 {
  margin: 0;
  color: #27282a;
  font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
  font-size: 64px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.29px; }

.page-error p {
  margin: 0 auto;
  padding: 49px 0 0 0;
  max-width: 356px;
  color: #27282a;
  font-family: "Open Sans", "Frank Ruhl Libre", sans-serif;
  font-size: 18px;
  text-align: center; }

.page-error-btn {
  display: flex;
  justify-content: center;
  padding: 59px 0 0 0; }
  .page-error-btn a {
    width: 405px;
    height: 92px; }

.materials-info {
  display: flex;
  flex-wrap: wrap; }

.section-title h3 {
  margin: 0;
  color: #27282a;
  font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
  font-size: 64px;
  font-weight: 700;
  letter-spacing: 0.23px; }

.section-title p {
  margin: 0;
  padding: 17px 0 0 0;
  color: #27282a;
  font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 0.1px; }

.materials-wrap {
  width: 69.6%;
  padding: 70px 25px 48px 0; }

.materials-bg {
  width: calc(100% - 69.6%);
  position: relative; }
  .materials-bg span {
    display: block;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0; }

.materials-list {
  max-width: 800px;
  padding: 26px 0; }
  .materials-list ul li {
    margin: 0 0 40px 0; }
    .materials-list ul li:last-child {
      margin: 0; }

.materials-item {
  padding: 31px 0 31px 148px;
  position: relative; }
  .materials-item .materials-item-eq {
    width: 183px;
    display: flex;
    align-items: center;
    padding: 15px 45px 15px 15px;
    background-color: #ffffff;
    color: #ffffff;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 80px;
    font-weight: 700;
    text-shadow: 1px 1px 0 #eebb56, -1px -1px 0 #eebb56, 1px -1px 0 #eebb56, -1px 1px 0 #eebb56, 1px 1px 0 #eebb56;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1; }
  .materials-item p {
    margin: 0;
    color: #27282a;
    font-family: "Open Sans", "Frank Ruhl Libre", sans-serif;
    font-size: 18px;
    line-height: 27px;
    position: relative;
    z-index: 2; }

.materials-advantage {
  padding: 112px 0 0 29px; }
  .materials-advantage p {
    margin: 0;
    color: #27282a;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 50px;
    font-weight: 700;
    line-height: 1.3; }

.main-section-wrap {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1; }
  .main-section-wrap:after {
    content: '';
    background: url(../images/letter.png) no-repeat center;
    background-size: contain;
    position: absolute;
    top: -1.5%;
    left: 0;
    right: 7%;
    bottom: 20%;
    z-index: -1;
    pointer-events: none; }

.main-section-text {
  width: 65.4%;
  padding: 109px 0;
  position: relative;
  z-index: 2; }

.main-section-img {
  width: calc(100% - 65.4%);
  position: relative;
  z-index: 1; }
  .main-section-img span {
    display: block;
    background-repeat: no-repeat;
    background-position: 50% 9.6%;
    background-size: 100% auto;
    position: absolute;
    top: -154px;
    left: -4px;
    right: -181px;
    bottom: 0; }

.main-title h1 {
  margin: 0;
  padding: 0 0 32px 0;
  color: #27282a;
  font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
  font-size: 82px;
  font-weight: 700;
  line-height: 1.21; }

.main-title p {
  margin: 0;
  padding: 0 0 32px 0;
  color: #27282a;
  font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
  font-size: 27px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.1px; }

.see-products {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 154px 0 68px 0; }
  .see-products p {
    margin: 0;
    padding: 13px 15px 10px 0;
    color: #27282a;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 27px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.35px;
    line-height: 1.3; }
  .see-products a.main-btn {
    width: 381px;
    height: 92px; }

.about-info-wrap {
  display: flex;
  flex-wrap: wrap; }

.about-info {
  width: 57%;
  padding: 70px 20px 0 0; }
  .about-info .section-list {
    padding: 50px 0; }
    .about-info .section-list ol {
      max-width: 668px; }
      .about-info .section-list ol li {
        margin: 0 0 46px 0;
        line-height: 1.36; }

.about-info-bg {
  width: calc(100% - 57%);
  position: relative; }
  .about-info-bg span {
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    bottom: -61px; }

.section-list ol li {
  padding: 0 0 0 37px;
  position: relative;
  color: #27282a;
  font-family: "Open Sans", "Frank Ruhl Libre", sans-serif;
  font-size: 17px; }
  .section-list ol li b {
    font-weight: 700; }
  .section-list ol li:before {
    content: '\e913';
    font-family: 'font-icon';
    color: #eebb56;
    font-size: 16px;
    position: absolute;
    top: 3px;
    left: 0; }

.our-mission {
  background-color: #edece6; }

.our-mission-wrap {
  display: flex;
  flex-wrap: wrap; }

.our-mission-img {
  width: 37.9%;
  position: relative; }
  .our-mission-img span {
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 0;
    left: -80px;
    right: 0;
    bottom: 0; }

.our-mission-text {
  width: calc(100% - 37.9%);
  padding: 155px 34px 85px 79px; }
  .our-mission-text h4 {
    margin: 0;
    color: #27282a;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 0.1px;
    line-height: 1.25; }

.products-wrap {
  padding: 69px 0 0 0; }

.products-list {
  padding: 12px 0 0 0; }

.processing-wrap {
  padding: 70px 0 103px 0; }

.processing-list {
  padding: 44px 0 15px 0;
  position: relative;
  z-index: 2; }

.processing-img {
  width: 78.34%;
  max-width: 962px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: -10%;
  right: -61%;
  bottom: -39%; }

.processing-item {
  display: flex; }

.processing-item-text {
  width: 100%;
  padding: 39px 25px 40px 40px;
  background-color: #ffffff; }
  .processing-item-text i {
    color: #998877;
    font-size: 55px; }
  .processing-item-text p {
    margin: 0;
    padding: 36px 0 0 0;
    color: #eebb56;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 60px;
    font-weight: 700;
    line-height: 1; }
    .processing-item-text p span {
      display: block;
      max-width: 253px;
      padding: 15px 0 0 0;
      color: #27282a;
      font-size: 22px;
      font-weight: 400;
      line-height: 1.3; }

.min-eq {
  padding: 31px 19px 25px 24px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  z-index: 1;
  background-color: #ffffff; }
  .min-eq:after, .min-eq:before {
    content: '';
    width: 21px;
    background: url(../images/border.svg) repeat-y;
    position: absolute;
    top: 10px;
    bottom: 0;
    pointer-events: none; }
  .min-eq:before {
    left: -10px; }
  .min-eq:after {
    right: -10px; }

.min-eq-number {
  width: 56.6%;
  padding: 15px 0; }
  .min-eq-number p {
    margin: 0;
    padding: 0 15px 0 0;
    color: #ffffff;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 330px;
    font-weight: 700;
    line-height: 244px;
    text-shadow: 1px 1px 0 #eebb56, -1px -1px 0 #eebb56, 1px -1px 0 #eebb56, -1px 1px 0 #eebb56, 1px 1px 0 #eebb56; }

.min-eq-text {
  width: calc(100% - 56.6%); }
  .min-eq-text p {
    margin: 0;
    color: #27282a;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 43px;
    font-weight: 700;
    line-height: 1.3; }

.production-info-wrap {
  display: flex;
  flex-wrap: wrap;
  padding: 70px 0 38px 0; }

.production-info {
  width: 45.5%;
  padding: 0 30px 96px 0; }
  .production-info .section-list {
    padding: 50px 0 0 0; }
    .production-info .section-list ol {
      max-width: 651px; }
      .production-info .section-list ol li {
        margin: 0 0 54px 0;
        line-height: 1.5; }
        .production-info .section-list ol li:last-child {
          margin: 0; }

.production-info-slider {
  width: calc(100% - 45.5%);
  padding: 12px 0 96px 0;
  display: flex;
  position: relative; }
  .production-info-slider .slider-control {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0; }
  .production-info-slider .pagination-wrap:after {
    display: none; }
  .production-info-slider .section-arrows {
    padding: 0;
    width: 146px; }

.production-view {
  width: 100%;
  padding-right: 384px; }
  .production-view .swiper-slide {
    margin-top: 17.6%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: margin-top .3s ease-in-out; }
    .production-view .swiper-slide.swiper-slide-active {
      margin-top: 0; }

.production-company {
  margin: 0 0 0 -58px;
  padding: 0 0 15px 0;
  pointer-events: none; }
  .production-company img {
    max-width: 100%;
    max-height: 100%; }

.equipment-data {
  position: relative;
  z-index: 2; }

.equipment-info {
  padding: 68px 0 19px 0;
  position: relative;
  z-index: 1; }
  .equipment-info .bg-text {
    max-width: 774px;
    color: #ffffff;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 260px;
    font-weight: 400;
    line-height: 260px;
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%); }

.equipment-text {
  position: relative;
  z-index: 2; }
  .equipment-text .section-list {
    padding: 55px 0 0 0; }
    .equipment-text .section-list ol {
      max-width: 850px; }
      .equipment-text .section-list ol li {
        margin: 0 0 41px 0; }

.equipment-renew {
  background-color: #998877; }

.equipment-renew-wrap {
  position: relative;
  z-index: 1; }

.equipment-renew-img {
  display: block;
  width: 579px;
  height: 275px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: -62%;
  left: 53%;
  z-index: 2; }

.equipment-renew-text {
  width: 50.5%;
  padding: 43px 0 51px 0;
  position: relative;
  z-index: 3; }
  .equipment-renew-text h4 {
    margin: 0;
    color: #ffffff;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 64px;
    font-weight: 700; }
  .equipment-renew-text p {
    margin: 0;
    padding: 19px 0 0 0;
    color: #ffffff;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 23px;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: 0.13px; }

.principles-wrap {
  padding: 70px 0 79px 0;
  position: relative;
  z-index: 1; }

.principles-bg {
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 60%;
  z-index: -1; }

.principles-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1px 0 0;
  padding: 54px 0; }

.principles-item {
  width: calc((100% / 2) - 1px);
  margin: 0 1px 1px 0;
  padding: 36px 39px;
  background-color: #ffffff;
  transition: background-color .3s ease-in-out; }
  .principles-item:hover {
    background-color: #eebb56; }
    .principles-item:hover .principles-icon {
      color: #27282a; }
    .principles-item:hover .principles-text h4,
    .principles-item:hover .principles-text p {
      color: #27282a; }

.principles-icon {
  height: 56px;
  margin: 0 0 47px 0;
  display: flex;
  color: #998877;
  font-size: 54px;
  transition: color .3s ease-in-out; }

.principles-text h4 {
  margin: 0;
  color: #eebb56;
  font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
  font-size: 34px;
  font-weight: 700;
  letter-spacing: 0.3px;
  transition: color .3s ease-in-out; }

.principles-text p {
  margin: 0;
  padding: 15px 0 0 0;
  color: #27282a;
  font-family: "Open Sans", "Frank Ruhl Libre", sans-serif;
  font-size: 17px;
  line-height: 1.5;
  transition: color .3s ease-in-out; }
  .principles-text p b {
    font-weight: 700; }

.prices-wrap {
  position: relative;
  z-index: 1; }

.prices-bg {
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  left: 80.3%;
  bottom: 0;
  pointer-events: none; }

.prices-data {
  padding: 69px 0 37px 0;
  position: relative;
  z-index: 2; }
  .prices-data .section-title {
    max-width: 80%; }

.section-form {
  padding: 53px 0 0 0; }

.fields-wrap {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 35px 31px 35px;
  background-color: #ffffff; }

.fields-title {
  width: 100%;
  padding: 0 15px 34px 15px; }
  .fields-title h3 {
    max-width: 1050px;
    margin: 0;
    color: #27282a;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 39px;
    font-weight: 700;
    line-height: 1.3;
    letter-spacing: 0.3px; }
  .fields-title p {
    max-width: 900px;
    margin: 0;
    padding: 17px 0 0 0;
    color: #27282a;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 29px;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: 0.3px; }

.field-item {
  width: calc(100% / 2);
  padding: 15px;
  margin: 0 0 4px 0; }
  .field-item label {
    display: flex;
    position: relative;
    color: #27282a;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 19px;
    font-weight: 400; }
    .field-item label.select-field {
      position: relative; }
      .field-item label.select-field:after {
        content: "\e900";
        font-family: 'font-icon';
        position: absolute;
        right: 3px;
        transform: rotate(90deg);
        font-size: 10px;
        top: 2px;
        color: #eebb56; }
    .field-item label input {
      display: block;
      width: 100%;
      padding: 0 0 24px 0;
      border: none;
      border-bottom: 1px solid #e1dfd6;
      background: none;
      transition: border-color .3s ease-in-out; }
      .field-item label input::placeholder {
        color: inherit; }
      .field-item label input:focus {
        border-color: #eebb56; }
    .field-item label select {
      display: block;
      width: 100%;
      padding: 0 0 24px 0;
      border: none;
      border-bottom: 1px solid #e1dfd6;
      background: none;
      appearance: none;
      transition: border-color .3s ease-in-out; }
      .field-item label select:focus {
        border-color: #eebb56; }
    .field-item label textarea {
      display: block;
      width: 100%;
      height: 139px;
      padding: 0 0 24px 0;
      border: none;
      border-bottom: 1px solid #e1dfd6;
      background: none;
      resize: none;
      transition: border-color .3s ease-in-out; }
      .field-item label textarea::placeholder {
        color: inherit; }
      .field-item label textarea:focus {
        border-color: #eebb56; }
    .field-item label.error input,
    .field-item label.error select,
    .field-item label.error textarea {
      border-color: red; }

.upload-file {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 33px 0 0 0; }
  .upload-file p {
    margin: 0;
    padding: 5px 39px 5px 0;
    color: #27282a;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 19px;
    font-weight: 400; }

.file-field {
  min-width: 178px;
  height: 60px;
  padding: 7px 55px 5px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: #f7f6f2;
  cursor: pointer; }
  .file-field label {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer; }
  .file-field span {
    padding: 0 15px 0 0;
    color: #27282a;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 700; }
    .file-field span.upload-file {
      display: none; }
  .file-field i {
    font-size: 20px;
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%); }
    .file-field i.icon-close {
      display: none; }
  .file-field input[type="file"] {
    position: absolute;
    opacity: 0;
    visibility: hidden; }
  .file-field.file-in label {
    pointer-events: none; }
  .file-field.file-in span.upload-text {
    display: none; }
  .file-field.file-in span.upload-file {
    display: block; }
  .file-field.file-in i.icon-upload {
    display: none; }
  .file-field.file-in i.icon-close {
    display: block; }

.form-submit button {
  width: 100%;
  height: 92px;
  font-size: 30px;
  justify-content: center; }
  .form-submit button span {
    min-width: 232px;
    width: auto; }

.form-agreement {
  padding: 20px 0 0 0;
  display: flex;
  align-items: center; }
  .form-agreement label {
    display: flex;
    position: relative;
    cursor: pointer; }
    .form-agreement label input {
      position: absolute;
      opacity: 0;
      visibility: hidden; }
      .form-agreement label input:checked + span:before {
        border-color: #eebb56;
        background-color: #eebb56; }
      .form-agreement label input:checked + span:after {
        opacity: 1; }
    .form-agreement label span {
      display: block;
      width: 22px;
      height: 22px;
      position: relative;
      /*color: #27282a;
      font-family: $FrankRuhlLibre;
      font-size: 14px;
      font-weight: 400;
      transition: font-weight .3s ease-in-out;*/ }
      .form-agreement label span:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 22px;
        height: 22px;
        border: 1px solid #d1d0cb;
        transition: background-color .3s ease-in-out, border-color .3s ease-in-out; }
      .form-agreement label span:after {
        content: '\e918';
        font-family: 'font-icon';
        position: absolute;
        top: 7px;
        left: 6px;
        font-size: 9px;
        color: #ffffff;
        opacity: 0;
        transition: opacity .3s ease-in-out; }
    .form-agreement label:hover span {
      font-weight: 600; }
      .form-agreement label:hover span:before {
        border-color: #eebb56; }
  .form-agreement p {
    width: calc(100% - 20px);
    margin: 0;
    display: block;
    padding: 5px 0 5px 15px;
    position: relative;
    color: #27282a;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    transition: font-weight .3s ease-in-out; }
    .form-agreement p a {
      color: inherit;
      transition: text-decoration .3s ease-in-out; }
    .form-agreement p:hover a {
      text-decoration: underline; }

/*.modal-init {
  &:not(.iziModal) {
    display: none;
  }
}*/
.form-sended {
  display: flex;
  flex-wrap: wrap; }

.success-img {
  display: block;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top center;
  width: 100%;
  max-width: 435px;
  position: absolute;
  top: 5%;
  right: 50%;
  bottom: 0;
  transform: translateX(56%);
  pointer-events: none; }

.success-item {
  width: 44%;
  position: relative;
  z-index: 1; }

.success-info {
  width: calc(100% - 44%);
  padding: 0 0 76px 48px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  min-height: 100vh; }

.centered {
  width: 100%;
  padding: 80px 0; }

.success-title h3 {
  margin: 0;
  color: #27282a;
  font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
  font-size: 56px;
  font-weight: 700; }

.success-title p {
  margin: 0;
  padding: 25px 0 0 0;
  color: #27282a;
  font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
  font-size: 37px;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0.4px; }

.success-message {
  padding: 88px 0 0 0; }
  .success-message p {
    margin: 0;
    padding: 0 0 17px 0;
    color: #27282a;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 27px;
    font-weight: 400; }
  .success-message a {
    display: block;
    max-width: 160px; }
    .success-message a img {
      width: 100%;
      max-width: 100%;
      max-height: 100%; }

.success-back {
  padding: 69px 0 0 0; }
  .success-back .main-btn {
    width: 447px;
    height: 92px; }

.info-content {
  max-width: 922px;
  margin: 0 auto;
  padding: 41px 0 100px 0; }

.info-title {
  padding: 0 0 60px 0; }
  .info-title h1 {
    margin: 0;
    color: #27282a;
    font-family: "Frank Ruhl Libre", "Open Sans", sans-serif;
    font-size: 64px;
    font-weight: 700;
    letter-spacing: 0.3px;
    text-align: center; }

.info-text p {
  margin: 0 0 26px 0;
  color: #27282a;
  font-family: "Open Sans", "Frank Ruhl Libre", sans-serif;
  font-size: 16px;
  line-height: 1.6; }
