$browser-context: 14; // Default

@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

@import "fonts.scss";
@import url('https://fonts.googleapis.com/css?family=Frank+Ruhl+Libre:300,400,500,700,900|Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap&subset=cyrillic');

$OpenSans: 'Open Sans', 'Frank Ruhl Libre', sans-serif;
$FrankRuhlLibre: 'Frank Ruhl Libre', 'Open Sans', sans-serif;

$MainText: #27282a;

html {
  background-color: #f7f6f2;
}

body {
  position: relative;
  min-width: em(320);
  color: $MainText;
  font-family: $OpenSans;
  overflow: hidden;
}

* {
  outline: none;
  word-wrap: break-word;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/*a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $MainText;
  transition: .3s;
}*/

a, a:focus, a:hover {
  text-decoration: none;
}

ol, ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
}

button {
  padding: 0;
  cursor: pointer;
}

input,
button,
textarea {
  -webkit-appearance: none;
}

#BlowupLens {
  top: 0;
}

.main-btn {
  width: 405px;
  height: 92px;
  padding: 10px 52px;
  display: flex;
  align-items: center;
  position: relative;
  border: none;
  background-color: #eebb56;
  color: #ffffff;
  font-family: $FrankRuhlLibre;
  font-size: 20px;
  font-weight: 700;
  span {
    width: 100%;
    display: block;
    //padding: 0 0 0 80px;
    position: relative;
    //text-align: right;
    &:after {
      content: '';
      width: 77px;
      height: 3px;
      background-color: #ffffff;
      position: absolute;
      top: 50%;
      //left: 0;
      transform: translateY(-50%);
      transition: .3s ease-in-out;
    }
  }
  &.left-line {
    span {
      padding: 0 0 0 80px;
      text-align: right;
      &:after {
        left: 0;
      }
    }
    &:hover {
      span {
        &:after {
          width: 129px;
          left: -52px;
        }
      }
    }
  }
  &.right-line {
    span {
      padding: 0 80px 0 0;
      text-align: left;
      &:after {
        right: 0;
      }
    }
    &:hover {
      span {
        &:after {
          width: 129px;
          right: -52px;
        }
      }
    }
  }
}

.mobile-sidebar {
  padding: 0;
  width: 30px;
  display: none;
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s ease-in-out;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: none;
    background-color: #edece6;
    cursor: pointer;
    i {
      font-family: 'font-icon';
      color: #998877;
      font-size: 10px;
    }
  }
  &.mobile-filter {
    /*opacity: 1;
    pointer-events: auto;*/
    button {
      i {
        &:before {
          content: "\e920";
        }
      }
    }
  }
  &.mobile-menu {
    /*opacity: 1;
    pointer-events: auto;*/
    button {
      i {
        &:before {
          content: "\e921";
        }
      }
    }
  }
  &.open {
    button {
      i {
        &:before {
          content: "\e908";
        }
      }
    }
  }
}

header {
  position: relative;
  z-index: 8;
  &.hide {
    .main-logo a {
      opacity: 0;
    }
  }
}

.header-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 55px 0;
}

.main-logo a {
  opacity: 1;
  transition: opacity .4s ease-in-out;
}

.fixed-logo,
.main-logo {
  a {
    display: block;
    max-width: 122px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
    &.fixed,
    &.open {
      position: fixed;
      top: 67px;
      left: 50px;
      z-index: 15;
    }
  }
}

.header-list {
  display: flex;
}

.header-item {
  margin: 0 68px 0 0;
  position: relative;
  &:hover {
    .item-text {
      //font-weight: 600;
      color: #eebb56;
      text-decoration: underline;
    }
  }
  a {
    display: flex;
    align-items: center;

  }
  p {
    margin: 0;
    padding: 0 18px 0 0;
    position: relative;
    display: flex;
    align-items: center;
    &:after {
      content: '\e900';
      font-family: 'font-icon';
      font-size: 9px;
      color: #eebb56;
      position: absolute;
      //top: 50%;
      right: 0;
      transform: rotate(90deg);
    }
  }

  ul {
    padding: 3px 0;
    position: absolute;
    top: calc(100% - 9px);
    left: 44px;
    right: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s ease-in-out;
    //border: 1px solid #edece6;
    //background-color: #f7f6f2;
    li {
      padding: 3px 10px 3px 16px;
      //background-color: #edece6;
      a {
        display: block;
        color: #27282a;
        font-family: $FrankRuhlLibre;
        font-size: 18px;
        font-weight: 400;
        white-space: nowrap;
        transition: color .3s ease-in-out;
        //transition: font-weight .3s ease-in-out;
        &:hover {
          //font-weight: 600;
          color: #eebb56;
          text-decoration: underline;
        }
      }
    }
    &.open {
        opacity: 1;
        pointer-events: auto;
    }
  }

  .item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 100%;
    background-color: #edece6;

    i {
      font-size: 16px;
      color: #998877;
    }
  }

  .item-text {
    padding: 0 0 0 16px;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 18px;
    font-weight: 400;
    white-space: nowrap;
    //transition: font-weight .3s ease-in-out;
    transition: color .3s ease-in-out;
  }

}

.left-sidebar {
  width: 240px;
  /*display: flex;
  flex-direction: column;
  justify-content: space-between;*/
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 7;

  display: flex;
}

.catalog-toolbar {
  max-height: 100%;
  display: flex;
  padding: 198px 0 30px 0;
}

.catalog-toolbar-wrap {
  width: 100%;
  padding: 0 33px 0 48px;
  overflow: auto;
}

.catalog-toolbar-title {
  padding: 0 0 40px 0;
  h1 {
    margin: 0;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 32px;
    font-weight: 700;
  }
}

.filter-item {
  padding: 12px 0;
  h3 {
    margin: 0;
    padding: 0 18px 0 0;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    position: relative;
    cursor: pointer;
    &:after {
      content: '\e901';
      font-family: 'font-icon';
      color: #eebb56;
      position: absolute;
      top: 3px;
      right: 0;
      font-size: 9px;
      transition: transform .3s ease-in-out;
    }
  }
  ol {
    display: none;
    padding: 12px 0;
    li {
      padding: 8px 0;
      label {
        display: flex;
        position: relative;
        cursor: pointer;
        input {
          position: absolute;
          opacity: 0;
          visibility: hidden;
          &:checked + span {
            &:before {
              border-color: #eebb56;
            }
            &:after {
              opacity: 1;
            }
          }
        }
        span {
          display: block;
          width: 100%;
          padding: 0 0 0 31px;
          position: relative;
          color: #27282a;
          font-family: $FrankRuhlLibre;
          font-size: 15px;
          font-weight: 400;
          transition: font-weight .3s ease-in-out;
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 14px;
            height: 14px;
            border-radius: 100%;
            border: 1px solid #d1d0cb;
            transition: border-color .3s ease-in-out;
          }

          &:after {
            content: '';
            //margin: 1px 0 0 1px;
            position: absolute;
            top: 1px;
            left: 1px;
            width: 12px;
            height: 12px;
            border-radius: 100%;
            border: 2px solid #ffffff;
            background: #eebb56;
            opacity: 0;
            transition: opacity .3s ease-in-out;
          }
        }

        &:hover {
          span {
            font-weight: 700;
            &:before {
              border-color: #eebb56;
            }
          }
        }
      }
    }
  }
  &.open {
    h3 {
      &:after {
        transform: rotate(-90deg);
      }
    }
    /* ol {
       display: block;
     }*/
  }
}

.page-nav {
  width: 100%;
  max-height: 100%;
  //padding: 130px 0 30px 0;
  //padding: 30px 0 30px 0;
  padding: 230px 0 30px 0;
  display: flex;
  //justify-content: center;
  flex-direction: column;
  ol {
    width: 100%;
    overflow: auto;
    li {
      padding: 15% 0;
      a {
        display: block;
        padding: 0 10px 0 60px;
        position: relative;
        color: #27282a;
        font-family: $FrankRuhlLibre;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 1px;
        text-transform: uppercase;
        //transition: font-weight .3s ease-in-out;
        transition: color .3s ease-in-out;
        &:before {
          content: '';
          width: 46px;
          height: 3px;
          background-color: #eebb56;
          opacity: 0;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          transition: opacity .3s ease-in-out;
        }
        &:hover {
          //font-weight: 700;
          color: #eebb56;
        }
      }
      &.active {
        a {
          font-weight: 700;
          &:before {
            opacity: 1;
          }
        }

      }
    }
  }
}

.right-sidebar {
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 15;
  pointer-events: none;
  .modal-close {
    display: none;
  }
  &.modal-open {
    .catalog-btn {
      display: none;
    }
    .modal-close {
      display: flex;
      background-color: #f7f6f2;
    }
  }
}

.catalog-btn,
.modal-close {
  min-height: 150px;
  padding: 48px 15px 48px 56px;
  background-color: #ffffff;
  display: flex;
  align-items: flex-end;
  color: #27282a;
  font-size: 42px;
  pointer-events: auto;
  i {
    transition: color .3s ease-in-out;
  }
  span {
    display: block;
    padding: 3px 0;
    margin: 0 0 0 21px;
    font-family: $FrankRuhlLibre;
    font-weight: 700;
    position: relative;

    &:after {
      content: '';
      width: 34px;
      height: 3px;
      background-color: #27282a;
      transition: width .3s ease-in-out;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  &:hover {
    i {
      color: #eebb56;
    }
    span {
      &:after {
        width: 100%;
      }
    }
  }
}

.go-back {
  min-height: 270px;
  display: flex;
  align-items: flex-end;
  padding: 30px 15px 55px 55px;
  background-color: #0070b7;
  color: #ffffff;
  font-family: $FrankRuhlLibre;
  font-size: 42px;
  font-weight: 700;
  line-height: 44px;
  pointer-events: auto;
  span {
    display: block;
    padding: 0 0 19px 0;
    position: relative;

    &:after {
      content: '';
      width: 34px;
      height: 3px;
      background-color: #ffffff;
      transition: width .3s ease-in-out;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  &:hover {
    span {
      &:after {
        width: 100%;
      }
    }
  }
}

.send-order {
  min-height: 270px;
  display: flex;
  //align-items: flex-end;
  align-items: self-start;
  flex-direction: column;
  justify-content: space-between;
  padding: 58px 15px 55px 55px;
  background-color: #0070b7;
  color: #ffffff;
  font-family: $FrankRuhlLibre;
  pointer-events: auto;
  span {
    //width: 100%;
    display: block;
    padding: 0 0 11px 0;
    &.request {
      width: 100%;
      color: #ffffff;
      font-size: 17px;
      font-weight: 400;
    }
    &.send-btn {
      position: relative;
      font-size: 42px;
      font-weight: 700;
      &:after {
        content: '';
        width: 34px;
        height: 3px;
        background-color: #ffffff;
        transition: width .3s ease-in-out;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }
  &:hover {
    span {
      &.send-btn {
        &:after {
          width: 100%;
        }
      }
    }
  }
}

.chosen-product {
  margin: 27px 0 0 0;
  padding: 0 0 70px 0;
  background-color: #edece6;
}

.chosen-product-wrap {
  display: flex;
  flex-wrap: wrap;
}

.item-slider-wrap {
  width: 44.4%;
  padding-top: 8px;
  display: flex;
  position: relative;
  z-index: 2;

  .slider-control {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .section-pagination,
  .section-arrows {
    background-color: #edece6;
  }
}

.item-slider-wrap,
.product-data {
  //position: relative;
  padding-bottom: 107px;
}

.item-view {
  display: flex;
  margin-left: -316px;
  padding-left: 316px;

  .swiper-slide {
    //margin: 0 0 0 1px;
    //max-width: 100%;
    background-color: #ffffff;
    display: flex;
    //justify-content: center;
    //align-items: flex-end;
    a {
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
    img {
      //cursor: none !important;
      opacity: 0;
      transition: opacity .3s ease-in-out;
      &.swiper-lazy-loaded {
        opacity: 1;
      }
    }
  }
}

.product-data {
  width: calc(100% - 44.4%);
  position: relative;
  z-index: 1;
}

.slider-control {
  padding: 9px 0;
}

.pagination-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  &:after {
    content: '';
    border-bottom: 2px solid #e3e2dc;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    z-index: -1;
    pointer-events: none;
  }
}

.section-pagination {
  width: auto;
  padding: 0 16px 0 0;
  display: flex;
  align-items: center;
  position: static;
  color: #27282a;
  font-family: $FrankRuhlLibre;
  font-size: 20px;
  font-weight: 400;

  .pagination-current {
    padding: 0 8px 0 0;
    color: #eebb56;
    font-size: 44px;
    font-weight: 700;
  }

  .pagination-total {
    //padding: 0 0 0 3px;
  }
}

.section-arrows {
  display: flex;
  padding: 0 0 0 16px;
  > div {
    &:first-child {
      margin: 0 10px 0 0;
    }
  }
}

/*.section-prev {
  margin: 0 10px 0 0;
}*/

.section-prev,
.section-next {
  width: 68px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: #eebb56;
  border-radius: 100%;
  background-color: #ffffff;
  i {
    transition: transform .3s ease-in-out;
  }
  &:not(.button-disabled) {
    cursor: pointer;
  }

  &.button-disabled {
    color: #d9d7cc;
    background-color: #e3e2dc;
  }
  &:hover {
    i {
      transform: rotate(360deg);
    }
  }
}

.pagination-bullet {
  margin: 0 7px;
  display: inline-block;
  cursor: pointer;
  &:after {
    content: '\e913';
    font-family: 'font-icon';
    color: #e0ded6;
    font-size: 17px;
    transition: color .3s ease-in-out;
  }
  &.active-bullet {
    &:after {
      color: #eebb56;
    }
  }
}

.section-bullets {
  width: calc(100% - 146px);
  padding: 0 116px 0 10px;
  text-align: center;
}

.product-info {
  //padding: 0 275px 32px 79px;
  padding: 0 0 0 81px;
  position: relative;
  z-index: 1;

  &:first-child {
    //background-color: #f7f6f2;
    &:after {
      content: '';
      width: 150vw;
      background-color: #f7f6f2;
      position: absolute;
      top: -1px;
      right: 50%;
      bottom: 0;
      transform: translateX(38%);
      z-index: -1;
      pointer-events: none;
    }
  }
}

.product-name {
  padding: 0 0 25px 0;

  h1 {
    margin: 0;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 44px;
    font-weight: 700;
  }

  p {
    margin: 0;
    padding: 8px 0 0 0;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 26px;
    font-weight: 400;
  }

}

.product-list {
  padding: 0 0 34px 0;

  li {
    padding: 12px 0;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 18px;
    font-weight: 400;

    b {
      font-weight: 700;
    }
  }
}

.product-price {
  padding: 37px 0 0 0;

  p {
    margin: 0;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 29px;
    font-weight: 700;
  }
}

.product-order {
  max-width: 381px;
  padding: 41px 0 0 0;

  p {
    margin: 0;
    padding: 0 0 26px 0;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 23px;
    font-weight: 700;
  }

  button {
    width: 381px;
    height: 92px;
    /*padding: 10px 52px;
    display: flex;
    align-items: center;
    position: relative;
    border: none;
    background-color: #eebb56;

    color: #ffffff;
    font-family: $FrankRuhlLibre;
    font-size: 20px;
    font-weight: 700;
    span {
      width: 100%;
      display: block;
      padding: 0 80px 0 0;
      position: relative;
      text-align: left;

      &:after {
        content: '';
        width: 77px;
        height: 3px;
        background-color: #ffffff;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }*/
  }
}

.goods-list {
  padding: 36px 0;
}

.goods-list-title {
  h2 {
    margin: 0;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 64px;
    font-weight: 700;
    letter-spacing: 0.8px;
  }
}

.goods-list-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1px 0 0;
  padding: 26px 0 0 0;
}

.goods-item {
  width: calc((100% / 3) - 1px);
  margin: 0 1px 1px 0;
  padding-bottom: 37.5%;
  position: relative;
  background-color: #ffffff;

  a {
    display: block;
    padding: 27px 30px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    transition: box-shadow .3s ease-in-out;

    span {
      display: block;
      max-width: 160px;
      padding: 0 0 8px 0;
      position: relative;
      color: #27282a;
      font-family: $FrankRuhlLibre;
      font-size: 23px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0.7px;
      transition: color .3s ease-in-out;

      &:after {
        content: '';
        width: 26px;
        height: 3px;
        background-color: #f2f1ed;
        position: absolute;
        left: 0;
        bottom: 0;
        transition: width .3s ease-in-out, background-color .3s ease-in-out;
      }
    }
  }

  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    pointer-events: none;

    &.default {
      transition: opacity .5s ease-in-out;
      z-index: 2;
    }

    &.hover {
      z-index: 1;
    }
  }

  &:hover {
    a {
      box-shadow: 0 2px 67px #e1dfd7;

      span {
        color: #eebb56;

        &:after {
          width: 58px;
          background-color: #eebb56;
        }
      }
    }

    img {
      &.default {
        opacity: 0;
        //z-index: 1;
      }

      &.hover {
        //z-index: 2;
      }
    }
  }
}

.load-more {
  padding: 79px 0 0 0;
  text-align: center;

  button {
    width: 241px;
    display: inline-flex;
    padding: 30px 65px 30px 32px;
    position: relative;
    color: #ffffff;
    font-family: $FrankRuhlLibre;
    font-size: 20px;
    font-weight: 700;
    border: none;
    background-color: #eebb56;

    i {
      font-size: 16px;
      position: absolute;
      top: 42%;
      right: 34px;
      pointer-events: none;
      transform: translateY(-50%);
      animation: spin 5s infinite linear;
      animation-play-state: paused;
    }
  }

  &.processing {
    button {
      i {
        animation-play-state: running;
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.goods-pagination {
  display: flex;
  justify-content: center;
  padding: 35px 0;

  .pagination-wrap {
    width: 100%;
    max-width: 441px;
  }

  .section-pagination,
  .section-arrows {
    background-color: #f7f6f2;
  }
}

.production-notice {
  padding: 59px 0 21px 0;

  p {
    margin: 0;
    max-width: 930px;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 44px;
    font-weight: 700;
    letter-spacing: 0.4px;
    line-height: 1.34;
  }
}

.map-title {
  position: relative;
  padding-bottom: 125px;
  margin-bottom: -33px;

  &:after {
    content: '';
    width: 2px;
    height: 125px;
    background-color: #eebb56;
    position: absolute;
    bottom: 0;
    left: 39px;

  }

  h3 {
    margin: 0;
    padding: 0 0 16px 0;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 44px;
    font-weight: 400;
  }
}

.contacts-wrap {
  display: flex;
  flex-wrap: wrap;
}

.map-wrap {
  width: calc(100% - 38.5%);
  position: relative;
}

#google-map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.contacts {
  width: 38.5%;
  padding: 71px 82px 30px 77px;
  background-color: #ffffff;

  h4 {
    margin: 0;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 64px;
    font-weight: 700;
  }
}

.contacts-info-list {
  padding: 17px 0 0 2px;
}

.contacts-info {
  padding: 18px 0 18px 2px;

  p {
    margin: 0;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 19px;
    font-weight: 400;
  }

  a {
    display: block;
    margin: 11px 0 0 0;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 24px;
    font-weight: 700;
    transition: color .3s ease-in-out;
    &:hover {
      color: #eebb56;
    }
  }
}

.social-links {
  padding: 29px 0 29px 5px;

  a {
    padding: 7px;
    margin-right: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;

    &.linked-in {
      width: 40px;
      height: 40px;
      color: #ffffff;
      border-radius: 100%;
      background-color: #0274b3;
    }
  }
}

.contacts-section {
  background-color: #ffffff;
  .contacts-bottom {
    padding-top: 36px;
  }
  .ready-for {
    display: block;
  }
}

.ready-for {
  display: none;
  margin: 0 -11px 0 0;
  padding: 26px 0 26px 5px;
  p {
    margin: 0;
    padding: 0 0 0 25px;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 28px;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: 0.2px;
    border-left: 3px solid #eebb56;
  }
}

.contacts-bottom {
  padding: 179px 0 20px 1px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.site-rights {
  padding: 5px 0;

  p {
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 13px;
    font-weight: 400;

    a {
      display: block;
      padding: 3px 0 0 0;
      color: inherit;
      text-decoration: underline;
    }
  }
}

.certificate-img {
  padding: 5px 15px;

  img {
    max-width: 47px;
  }
}

.made-in {
  padding: 5px 0;

  a {
    span {
      display: block;
      color: #27282a;
      font-family: $FrankRuhlLibre;
      font-size: 13px;
      font-weight: 400;
    }

    i {
      font-size: 14px;
      color: #ed1c24;
    }
  }
}

.gmnoprint,
.gm-fullscreen-control {
  display: none;
}

.catalog-slider {
  position: relative;
}

.category-view-control {
  padding: 0 0 104px 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  .slider-control {
    width: 38%;
  }
  .section-pagination,
  .section-arrows {
    background-color: #f7f6f2;
  }
  .pagination-wrap:after {
    border-bottom: 2px solid #edece6;
  }
}

.catalog-category {
  //min-height: calc(100vh - 154px);
  min-height: calc(100vh - 89px);
  display: flex;
  flex-wrap: wrap;
}

.category-img {
  width: 50%;
  position: relative;
  z-index: 1;
}

.category-view {
  margin: -65px 0 0 0;
  .swiper-slide {
    //padding: 65px 0 0 0;
    background-color: #f7f6f2;
  }
}

.category-img-wrap {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -147px;
  left: -117px;
  img {
    max-width: 100%;
    max-height: 100%;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    pointer-events: none;
    &.swiper-lazy-loaded {
      opacity: 1;
    }
  }
  a {
    padding: 10px;
    color: #0070b7;
    position: absolute;
    left: 32%;
    top: 43%;
    span {
      width: 137px;
      height: 137px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;
      border-radius: 100%;
      background-color: #ffffff;
      position: relative;
      z-index: 2;
      i {
        font-size: 25px;
        padding: 0 0 4px 4px;
      }
      &:before {
        content: '';
        border-radius: 100%;
        border: 3px solid #ffffff;
        position: absolute;
        top: -9px;
        left: -9px;
        right: -9px;
        bottom: -9px;
        z-index: 1;
        pointer-events: none;
      }
    }
  }
}

.category-info {
  width: 50%;
  padding: 65px 0 190px 0;
  position: relative;
  z-index: 2;
  pointer-events: none;
}

.category-title {
  padding: 10px 0 0 0;
  pointer-events: auto;
  h3 {
    margin: 0;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 64px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1.3;
  }
  p {
    margin: 0;
    padding: 11px 0 0 0;
    color: #ffffff;
    font-family: $FrankRuhlLibre;
    //font-size: 178px;
    font-size: 116px;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.not-found-wrap {
  padding: 44px 0 155px 0;
}

.error-type {
  h2 {
    margin: 0;
    margin-top: -16.5%;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 240px;
    font-weight: 700;
    text-align: center;
    position: relative;
    z-index: 1;
  }
  p {
    margin: 0;
    color: #ffffff;
    font-family: $FrankRuhlLibre;
    font-size: 300px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
    white-space: nowrap;
    letter-spacing: 5px;
  }
}

.page-error {
  h1 {
    margin: 0;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 64px;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.29px;
  }
  p {
    margin: 0 auto;
    padding: 49px 0 0 0;
    max-width: 356px;
    color: #27282a;
    font-family: $OpenSans;
    font-size: 18px;
    text-align: center;
  }
}

.page-error-btn {
  display: flex;
  justify-content: center;
  padding: 59px 0 0 0;
  a {
    width: 405px;
    height: 92px;
  }
}

.materials-info {
  display: flex;
  flex-wrap: wrap;
}

.section-title {
  h3 {
    margin: 0;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 64px;
    font-weight: 700;
    letter-spacing: 0.23px;
  }
  p {
    margin: 0;
    padding: 17px 0 0 0;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 32px;
    font-weight: 400;
    letter-spacing: 0.1px;
  }
}

.materials-wrap {
  width: 69.6%;
  padding: 70px 25px 48px 0;
}

.materials-bg {
  width: calc(100% - 69.6%);
  position: relative;
  span {
    display: block;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    //right: -316px;
    bottom: 0;
  }
}

.materials-list {
  max-width: 800px;
  padding: 26px 0;
  ul {
    li {
      margin: 0 0 40px 0;
      &:last-child {
        margin: 0;
      }
    }
  }
}

.materials-item {
  padding: 31px 0 31px 148px;
  position: relative;
  .materials-item-eq {
    width: 183px;
    display: flex;
    align-items: center;
    padding: 15px 45px 15px 15px;
    background-color: #ffffff;

    color: #ffffff;
    font-family: $FrankRuhlLibre;
    font-size: 80px;
    font-weight: 700;
    text-shadow: 1px 1px 0 #eebb56,
    -1px -1px 0 #eebb56,
    1px -1px 0 #eebb56,
    -1px 1px 0 #eebb56,
    1px 1px 0 #eebb56;

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
  }
  p {
    margin: 0;
    color: #27282a;
    font-family: $OpenSans;
    font-size: 18px;
    line-height: 27px;
    position: relative;
    z-index: 2;
  }
}

.materials-advantage {
  padding: 112px 0 0 29px;
  p {
    margin: 0;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 50px;
    font-weight: 700;
    line-height: 1.3;
  }
}

.main-section-wrap {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  &:after {
    content: '';
    background: url(../images/letter.png) no-repeat center;
    background-size: contain;
    position: absolute;
    top: -1.5%;
    left: 0;
    right: 7%;
    bottom: 20%;
    z-index: -1;
    pointer-events: none;
  }
}

.main-section-text {
  //max-width: 831px;
  width: 65.4%;
  padding: 109px 0;
  position: relative;
  z-index: 2;
}

.main-section-img {
  width: calc(100% - 65.4%);
  position: relative;
  z-index: 1;
  span {
    display: block;
    background-repeat: no-repeat;
    //background-position: center;
    background-position: 50% 9.6%;
    background-size: 100% auto;
    position: absolute;
    top: -154px;
    left: -4px;
    right: -181px;
    bottom: 0;
  }
}

.main-title {
  h1 {
    margin: 0;
    padding: 0 0 32px 0;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 82px;
    font-weight: 700;
    line-height: 1.21;
  }
  p {
    margin: 0;
    padding: 0 0 32px 0;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 27px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.1px;
  }
}

.see-products {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 154px 0 68px 0;
  p {
    margin: 0;
    padding: 13px 15px 10px 0;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 27px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.35px;
    line-height: 1.3;
  }
  a {
    &.main-btn {
      width: 381px;
      height: 92px;
    }
  }
}

.about-info-wrap {
  display: flex;
  flex-wrap: wrap;
}

.about-info {
  width: 57%;
  padding: 70px 20px 0 0;
  .section-list {
    padding: 50px 0;
    ol {
      max-width: 668px;
      li {
        margin: 0 0 46px 0;
        line-height: 1.36;
      }
    }
  }
}

.about-info-bg {
  width: calc(100% - 57%);
  position: relative;
  span {
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    //right: 0;
    bottom: -61px;
  }
}

.section-list {
  ol {
    li {
      padding: 0 0 0 37px;
      position: relative;
      color: #27282a;
      font-family: $OpenSans;
      font-size: 17px;
      b {
        font-weight: 700;
      }
      &:before {
        content: '\e913';
        font-family: 'font-icon';
        color: #eebb56;
        font-size: 16px;
        position: absolute;
        top: 3px;
        left: 0;
      }
    }
  }
}

.our-mission {
  background-color: #edece6;
}

.our-mission-wrap {
  display: flex;
  flex-wrap: wrap;
}

.our-mission-img {
  width: 37.9%;
  position: relative;
  span {
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 0;
    left: -80px;
    right: 0;
    bottom: 0;
  }
}

.our-mission-text {
  width: calc(100% - 37.9%);
  padding: 155px 34px 85px 79px;
  h4 {
    margin: 0;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 0.1px;
    line-height: 1.25;
  }
}

.products-wrap {
  padding: 69px 0 0 0;
}

.products-list {
  padding: 12px 0 0 0;
}

.processing-wrap {
  padding: 70px 0 103px 0;
}

.processing-list {
  padding: 44px 0 15px 0;
  position: relative;
  z-index: 2;
}

.processing-img {
  width: 78.34%;
  max-width: 962px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: -10%;
  right: -61%;
  bottom: -39%;
}

.processing-item {
  display: flex;
}

.processing-item-text {
  width: 100%;
  padding: 39px 25px 40px 40px;
  background-color: #ffffff;
  i {
    color: #998877;
    font-size: 55px;
  }
  p {
    margin: 0;
    padding: 36px 0 0 0;
    color: #eebb56;
    font-family: $FrankRuhlLibre;
    font-size: 60px;
    font-weight: 700;
    line-height: 1;
    span {
      display: block;
      max-width: 253px;
      padding: 15px 0 0 0;
      color: #27282a;
      font-size: 22px;
      font-weight: 400;
      line-height: 1.3;
    }
  }
}

.min-eq {
  //padding: 41px;
  padding: 31px 19px 25px 24px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  z-index: 1;
  //height: 330px;
  background-color: #ffffff;
  &:after,
  &:before {
    content: '';
    width: 21px;
    background: url(../images/border.svg) repeat-y;
    position: absolute;
    top: 10px;
    bottom: 0;
    pointer-events: none;
  }
  &:before {
    left: -10px;
  }
  &:after {
    right: -10px;
  }

}

.min-eq-number {
  width: 56.6%;
  padding: 15px 0;
  p {
    margin: 0;
    padding: 0 15px 0 0;
    //border: 1px solid #eebb56;
    color: #ffffff;
    font-family: $FrankRuhlLibre;
    font-size: 330px;
    font-weight: 700;
    line-height: 244px;
    text-shadow: 1px 1px 0 #eebb56,
    -1px -1px 0 #eebb56,
    1px -1px 0 #eebb56,
    -1px 1px 0 #eebb56,
    1px 1px 0 #eebb56;
  }
}

.min-eq-text {
  width: calc(100% - 56.6%);
  p {
    margin: 0;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 43px;
    font-weight: 700;
    line-height: 1.3;
  }
}

.production-info-wrap {
  display: flex;
  flex-wrap: wrap;
  padding: 70px 0 38px 0;
}

.production-info {
  width: 45.5%;
  padding: 0 30px 96px 0;
  .section-list {
    padding: 50px 0 0 0;
    ol {
      max-width: 651px;
      //max-width: 625px;
      li {
        margin: 0 0 54px 0;
        line-height: 1.5;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

.production-info-slider {
  width: calc(100% - 45.5%);
  padding: 12px 0 96px 0;
  display: flex;
  position: relative;
  .slider-control {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .pagination-wrap:after {
    display: none;
  }
  .section-arrows {
    padding: 0;
    width: 146px;
  }
}

.production-view {
  width: 100%;
  //padding-right: 316px;
  padding-right: 384px;
  .swiper-slide {
    margin-top: 17.6%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: margin-top .3s ease-in-out;
    &.swiper-slide-active {
      margin-top: 0;
    }
  }
}

.production-company {
  margin: 0 0 0 -58px;
  padding: 0 0 15px 0;
  pointer-events: none;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.equipment-data {
  position: relative;
  z-index: 2;
}

.equipment-info {
  padding: 68px 0 19px 0;
  position: relative;
  z-index: 1;
  .bg-text {
    max-width: 774px;
    color: #ffffff;
    font-family: $FrankRuhlLibre;
    font-size: 260px;
    font-weight: 400;
    line-height: 260px;
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    //right: -316px;
    left: 50%;
    transform: translateY(-50%);
  }
}

.equipment-text {
  position: relative;
  z-index: 2;
  .section-list {
    padding: 55px 0 0 0;
    ol {
      max-width: 850px;
      li {
        margin: 0 0 41px 0;
      }
    }
  }
}

.equipment-renew {
  background-color: #998877;
}

.equipment-renew-wrap {
  position: relative;
  z-index: 1;
}

.equipment-renew-img {
  display: block;
  width: 579px;
  height: 275px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: -62%;
  left: 53%;
  z-index: 2;
}

.equipment-renew-text {
  width: 50.5%;
  padding: 43px 0 51px 0;
  position: relative;
  z-index: 3;
  h4 {
    margin: 0;
    color: #ffffff;
    font-family: $FrankRuhlLibre;
    font-size: 64px;
    font-weight: 700;
  }
  p {
    margin: 0;
    padding: 19px 0 0 0;
    color: #ffffff;
    font-family: $FrankRuhlLibre;
    font-size: 23px;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: 0.13px;
  }
}

.principles-wrap {
  padding: 70px 0 79px 0;
  position: relative;
  z-index: 1;
}

.principles-bg {
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 60%;
  z-index: -1;
}

.principles-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1px 0 0;
  padding: 54px 0;
}

.principles-item {
  width: calc((100% / 2) - 1px);
  margin: 0 1px 1px 0;
  padding: 36px 39px;
  background-color: #ffffff;
  transition: background-color .3s ease-in-out;
  &:hover {
    background-color: #eebb56;
    .principles-icon {
      color: #27282a;
    }
    .principles-text {
      h4,
      p {
        color: #27282a;
      }
    }
  }
}

.principles-icon {
  height: 56px;
  margin: 0 0 47px 0;
  display: flex;
  color: #998877;
  font-size: 54px;
  transition: color .3s ease-in-out;
}

.principles-text {
  h4 {
    margin: 0;
    color: #eebb56;
    font-family: $FrankRuhlLibre;
    font-size: 34px;
    font-weight: 700;
    letter-spacing: 0.3px;
    transition: color .3s ease-in-out;
  }
  p {
    margin: 0;
    padding: 15px 0 0 0;
    color: #27282a;
    font-family: $OpenSans;
    font-size: 17px;
    line-height: 1.5;
    transition: color .3s ease-in-out;
    b {
      font-weight: 700;
    }
  }
}

.prices-wrap {
  position: relative;
  z-index: 1;
}

.prices-bg {
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  left: 80.3%;
  bottom: 0;
  pointer-events: none;
}

.prices-data {
  padding: 69px 0 37px 0;
  position: relative;
  z-index: 2;
  .section-title {
    max-width: 80%;
  }
}

.section-form {
  padding: 53px 0 0 0;
}

.fields-wrap {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 35px 31px 35px;
  background-color: #ffffff;
}

.fields-title {
  width: 100%;
  padding: 0 15px 34px 15px;
  h3 {
    max-width: 1050px;
    margin: 0;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 39px;
    font-weight: 700;
    line-height: 1.3;
    letter-spacing: 0.3px;
  }
  p {
    max-width: 900px;
    margin: 0;
    padding: 17px 0 0 0;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 29px;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: 0.3px;
  }
}

.field-item {
  width: calc(100% / 2);
  padding: 15px;
  margin: 0 0 4px 0;
  label {
    display: flex;
    position: relative;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 19px;
    font-weight: 400;
    &.select-field {
      position: relative;
      &:after {
        content: "\e900";
        font-family: 'font-icon';
        position: absolute;
        right: 3px;
        transform: rotate(90deg);
        font-size: 10px;
        top: 2px;
        color: #eebb56;
      }
    }
    input {
      display: block;
      width: 100%;
      padding: 0 0 24px 0;
      border: none;
      border-bottom: 1px solid #e1dfd6;
      background: none;
      transition: border-color .3s ease-in-out;
      &::placeholder {
        color: inherit;
      }
      &:focus {
        border-color: #eebb56;
      }
    }
    select {
      display: block;
      width: 100%;
      padding: 0 0 24px 0;
      border: none;
      border-bottom: 1px solid #e1dfd6;
      background: none;
      appearance: none;
      transition: border-color .3s ease-in-out;
      &:focus {
        border-color: #eebb56;
      }
    }
    textarea {
      display: block;
      width: 100%;
      height: 139px;
      padding: 0 0 24px 0;
      border: none;
      border-bottom: 1px solid #e1dfd6;
      background: none;
      resize: none;
      transition: border-color .3s ease-in-out;
      &::placeholder {
        color: inherit;
      }
      &:focus {
        border-color: #eebb56;
      }
    }
    &.error {
      input,
      select,
      textarea {
        border-color: red;
      }

    }
  }
}

.upload-file {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 33px 0 0 0;
  p {
    margin: 0;
    padding: 5px 39px 5px 0;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 19px;
    font-weight: 400;
  }
}

.file-field {
  min-width: 178px;
  height: 60px;
  padding: 7px 55px 5px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: #f7f6f2;
  cursor: pointer;
  label {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
  }
  span {
    padding: 0 15px 0 0;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 16px;
    font-weight: 700;
    &.upload-file {
      display: none;
    }
  }
  i {
    font-size: 20px;
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    &.icon-close {
      display: none;
    }
  }
  input[type="file"] {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }
  &.file-in {
    label {
      pointer-events: none;
    }
    span {
      &.upload-text {
        display: none;
      }
      &.upload-file {
        display: block;
      }
    }
    i {
      &.icon-upload {
        display: none;
      }
      &.icon-close {
        display: block;
      }
    }
  }
}

.form-submit {
  button {
    width: 100%;
    height: 92px;
    font-size: 30px;
    justify-content: center;
    span {
      min-width: 232px;
      width: auto;
    }
  }
}

.form-agreement {
  padding: 20px 0 0 0;
  display: flex;
  align-items: center;
  label {
    display: flex;
    position: relative;
    cursor: pointer;
    input {
      position: absolute;
      opacity: 0;
      visibility: hidden;
      &:checked + span {
        &:before {
          border-color: #eebb56;
          background-color: #eebb56;
        }
        &:after {
          opacity: 1;
        }
      }
    }
    span {
      display: block;
      width: 22px;
      height: 22px;
      //width: 100%;
      //padding: 5px 0 5px 37px;
      position: relative;
      /*color: #27282a;
      font-family: $FrankRuhlLibre;
      font-size: 14px;
      font-weight: 400;
      transition: font-weight .3s ease-in-out;*/
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 22px;
        height: 22px;
        //border: 1px solid #eebb56;
        border: 1px solid #d1d0cb;
        transition: background-color .3s ease-in-out, border-color .3s ease-in-out;
      }
      &:after {
        content: '\e918';
        font-family: 'font-icon';
        position: absolute;
        top: 7px;
        left: 6px;
        font-size: 9px;
        color: #ffffff;
        opacity: 0;
        transition: opacity .3s ease-in-out;
      }
    }
    &:hover {
      span {
        font-weight: 600;
        &:before {
          border-color: #eebb56;
        }
      }
    }
  }
  p {
    width: calc(100% - 20px);
    margin: 0;
    display: block;
    padding: 5px 0 5px 15px;
    position: relative;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 14px;
    font-weight: 400;
    transition: font-weight .3s ease-in-out;
    a {
      color: inherit;
      transition: text-decoration .3s ease-in-out;
    }
    &:hover {
      a {
        text-decoration: underline;
      }
      //font-weight: 600;
    }
  }
}

/*.modal-init {
  &:not(.iziModal) {
    display: none;
  }
}*/

.form-sended {
  display: flex;
  flex-wrap: wrap;
}

.success-img {
  display: block;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top center;
  width: 100%;
  max-width: 435px;
  position: absolute;
  top: 5%;
  //left: 0;
  right: 50%;
  bottom: 0;
  transform: translateX(56%);
  pointer-events: none;
}

.success-item {
  width: 44%;
  position: relative;
  z-index: 1;
}

.success-info {
  width: calc(100% - 44%);
  padding: 0 0 76px 48px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  min-height: 100vh;
}

.centered {
  width: 100%;
  padding: 80px 0;
}

.success-title {
  h3 {
    margin: 0;

    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 56px;
    font-weight: 700;
  }
  p {
    margin: 0;
    padding: 25px 0 0 0;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 37px;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: 0.4px;
  }
}

.success-message {
  padding: 88px 0 0 0;
  p {
    margin: 0;
    padding: 0 0 17px 0;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 27px;
    font-weight: 400;
  }
  a {
    display: block;
    max-width: 160px;
    img {
      width: 100%;
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.success-back {
  padding: 69px 0 0 0;
  .main-btn {
    width: 447px;
    height: 92px;
  }
}

.info-content {
  max-width: 922px;
  margin: 0 auto;
  padding: 41px 0 100px 0;
}

.info-title {
  padding: 0 0 60px 0;
  h1 {
    margin: 0;
    color: #27282a;
    font-family: $FrankRuhlLibre;
    font-size: 64px;
    font-weight: 700;
    letter-spacing: 0.3px;
    text-align: center;
  }
}

.info-text {
  p {
    margin: 0 0 26px 0;

    color: #27282a;
    font-family: $OpenSans;
    font-size: 16px;
    line-height: 1.6;
  }
}