@font-face {
  font-family: 'font-icon';
  src:  url('../fonts/font-icon/font-icon.eot?yj7fja');
  src:  url('../fonts/font-icon/font-icon.eot?yj7fja#iefix') format('embedded-opentype'),
  url('../fonts/font-icon/font-icon.ttf?yj7fja') format('truetype'),
  url('../fonts/font-icon/font-icon.woff?yj7fja') format('woff'),
  url('../fonts/font-icon/font-icon.svg?yj7fja#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'font-icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-right:before {
  content: "\e900";
}
.icon-left:before {
  content: "\e901";
}
.icon-load:before {
  content: "\e902";
}
.icon-linkedIn:before {
  content: "\e903";
}
.icon-redlab:before {
  content: "\e904";
}
.icon-earth:before {
  content: "\e905";
}
.icon-envelope:before {
  content: "\e906";
}
.icon-phone:before {
  content: "\e907";
}
.icon-close:before {
  content: "\e908";
}
.icon-dots:before {
  content: "\e909";
}
.icon-coat:before {
  content: "\e910";
}
.icon-jackets:before {
  content: "\e911";
}
.icon-raincoat:before {
  content: "\e912";
}
.icon-list:before {
  content: "\e913";
}
.icon-recycling:before {
  content: "\e914";
}
.icon-standards:before {
  content: "\e915";
}
.icon-energy:before {
  content: "\e916";
}
.icon-certificate:before {
  content: "\e917";
}
.icon-checked:before {
  content: "\e918";
}
.icon-upload:before {
  content: "\e919";
}
.icon-filter:before {
  content: "\e920";
}
.icon-bars:before {
  content: "\e921";
}
.icon-play:before {
  content: "\e922";
}